import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-create-super-agent',
  templateUrl: './create-super-agent.component.html',
  styleUrls: ['./create-super-agent.component.scss']
})
export class CreateSuperAgentComponent implements OnInit {

  sent = false;

  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    latitude: new UntypedFormControl('9'),
    longitude: new UntypedFormControl('127')
  });

  constructor(private httpClient: HttpClient, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

  create() {
    let superAgent = {
      name: this.form.value.name,
      email: this.form.value.email,
      phone: this.form.value.phone,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude
    };
    this.httpClient.post('/api/superagents',superAgent, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.sent = true;
    });
  }

  back() {
    this.router.navigate(['/']);
  }

}
