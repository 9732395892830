import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { WsService } from './ws.service';
import { BehaviorSubject, distinctUntilChanged, ReplaySubject } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  
  constructor(private wsService: WsService, private authService: AuthService) { 
    this.authService.authenticatedUser.subscribe((user) => {
      this.currentUserSubject.next(user);
    });
    this.wsService.isConnected.subscribe((isConnected) => {
      if (isConnected) {
        this.wsService.observable.subscribe((message: any) => {
          //let message = JSON.parse(messageEvent.data);
          console.log("Received message " + JSON.stringify(message));
          if (this.authService.isAuthenticated) {
            if (message.type == 'user') {
              let user = this.currentUserSubject.getValue();
              if (message.id === user.id) {
                if (message.balances !== undefined) {
                  user.balance = message.balance;
                  user.balances = message.balances;
                }
                if (message.cashOnHand !== undefined) {
                  user.cash = message.cash;
                  user.cashOnHand = message.cashOnHand;
                }
                this.currentUserSubject.next(user);
              }
            }
          }
        });
      }
    });
    
  }
}
