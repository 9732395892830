import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-admin-totals',
  templateUrl: './admin-totals.component.html',
  styleUrls: ['./admin-totals.component.scss']
})
export class AdminTotalsComponent implements OnInit {

  currency = 'PHP';

  totals?: any;
  globalTotals?: any;

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient, private modalService: NgbModal, private wsService: WsService, private userService: UserService, private authService: AuthService) { 
    let totals = this.route.snapshot.data['totals'];
    if (totals.result == 0) {
      console.log('Results is zero');
      this.totals = totals.totals;
      this.globalTotals = totals.globalTotals;
      console.log('Float is ' + this.totals.FLOAT);
    } else {
      console.log('Result is not zero');
    }
  }

  ngOnInit(): void {
    //this.googleMap!.setMyLocationEnabled(true);
    /*this.route.paramMap.subscribe(params => { 
      this.user = null;
      this.currency = params.get('id')!; 
      this.httpClient.get('/api/admin/users/' + this.id,  {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((result: any) => {
        this.user = result;
      */
      }
}
