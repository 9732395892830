<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Give Cash</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)">
      </zxing-scanner>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="close()">Get Cash</button>
  </div>