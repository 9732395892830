<div *ngIf="isAuthenticated && user.agent" class="d-flex flex-column align-items-center">
    <div class="title">Cash On Hand</div>
    <div class="balances">
      <div *ngFor="let cash of cashOnHand" class="balance">{{ cash.amount | currency:cash.currency }}</div>
  </div>
    <div class="buttons">
      <button type="button" class="btn btn-primary mr-2" (click)="setCash()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash" viewBox="0 0 16 16">
          <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
          <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z"/>
        </svg> Set Cash On Hand
      </button>
    </div>
  </div>