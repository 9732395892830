import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  @Input() offer: any;

  message?: string;

  constructor(public activeModal: NgbActiveModal, private httpClient: HttpClient, private authService: AuthService) {}

  ngOnInit(): void {
  }

  acceptOffer() {

      this.httpClient.post('/api/bookings/' + this.offer.booking + '/offers/' + this.offer.id, {
        role: this.offer.role
      }, { headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)}).subscribe((result: any)=> {
        if (result.result == 0) {
          //window.alert('Accepted');
          this.activeModal.close(true);
        } else {
          this.message = 'Denied';
          //window.alert('Denied');
        }
      })
  }

  declineOffer() {
    this.activeModal.close(false);
  }

}
