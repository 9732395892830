import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-billers',
  templateUrl: './billers.component.html',
  styleUrls: ['./billers.component.scss']
})
export class BillersComponent implements OnInit {

  billers?: any;

  user?: any;

  constructor(private httpClient: HttpClient, private wsService: WsService, private router: Router, private route: ActivatedRoute, private userService: UserService, private authService: AuthService) { 
    this.billers = this.route.snapshot.data['billers'];

    this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {

  }

  back() {
    this.router.navigate(['/']);
  }

}
