import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalizedString } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router, RouterEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, filter, map, Observable, of, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BookingsService } from 'src/app/services/bookings.service';
import { LocationService } from 'src/app/services/location.service';
import { WsService } from 'src/app/services/ws.service';
import { Location } from 'src/app/models/location';
import { BookingGivecashComponent } from '../booking-givecash/booking-givecash.component';
import { GoogleMap } from '@angular/google-maps';
import { UserService } from 'src/app/services/user.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BookingSenderagentcancelComponent } from '../booking-senderagentcancel/booking-senderagentcancel.component';
import { BookingReceiveragentcancelComponent } from '../booking-receiveragentcancel/booking-receiveragentcancel.component';
import { BookingCancelComponent } from '../booking-cancel/booking-cancel.component';
import { BookingFeedbackForRoleComponent } from '../booking-feedback-for-role/booking-feedback-for-role.component';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  id?: string;
  user?: any;
  booking?: any;

  fromBookings = false;
  fromAdminBookings = false;

  messages = <any>[];
  formGroup = new UntypedFormGroup({
    message: new UntypedFormControl('')
  });

  apiLoaded: Observable<boolean>;

  @ViewChild('map', { static: false }) googleMap: GoogleMap | undefined;

  center: google.maps.LatLngLiteral = { lat: 24, lng: 12 };
  zoom = 4;
  outletMarkerOptions: google.maps.MarkerOptions = {
    draggable: false
  };

  senderMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      path: "M46.542,20.032 Q49.448,20.032 51.487,22.071 Q53.526,24.11 53.526,27.016 Q53.526,28.469 52.799,30.344 Q52.072,32.219 51.041,33.86 Q50.01,35.501 49.002,36.93 Q47.994,38.359 47.291,39.203 L46.541,40 Q46.26,39.672 45.791,39.133 Q45.322,38.594 44.103,36.977 Q42.884,35.36 41.97,33.836 Q41.056,32.312 40.306,30.391 Q39.556,28.47 39.556,27.016 Q39.556,24.11 41.595,22.071 Q43.634,20.032 46.54,20.032 L46.542,20.032 z M46.408,21.782 Q45.736,21.782 45.064,21.964 Q44.392,22.146 43.86,22.524 Q43.328,22.902 42.999,23.469 Q42.67,24.036 42.67,24.806 Q42.67,25.492 42.929,25.975 Q43.188,26.458 43.608,26.78 Q44.028,27.102 44.56,27.305 Q45.092,27.508 45.652,27.648 Q46.198,27.802 46.73,27.928 Q47.262,28.054 47.682,28.222 Q48.102,28.39 48.361,28.642 Q48.62,28.894 48.62,29.3 Q48.62,29.678 48.424,29.923 Q48.228,30.168 47.934,30.308 Q47.64,30.448 47.304,30.497 Q46.968,30.546 46.674,30.546 Q46.24,30.546 45.834,30.441 Q45.428,30.336 45.127,30.119 Q44.826,29.902 44.644,29.552 Q44.462,29.202 44.462,28.698 L42.334,28.698 Q42.32,29.622 42.67,30.294 Q43.02,30.966 43.615,31.4 Q44.21,31.834 44.987,32.037 Q45.764,32.24 46.59,32.24 Q47.612,32.24 48.389,32.002 Q49.166,31.764 49.691,31.337 Q50.216,30.91 50.482,30.322 Q50.748,29.734 50.748,29.048 Q50.748,28.208 50.391,27.669 Q50.034,27.13 49.544,26.808 Q49.054,26.486 48.557,26.339 Q48.06,26.192 47.78,26.136 Q46.842,25.898 46.261,25.744 Q45.68,25.59 45.351,25.436 Q45.022,25.282 44.91,25.1 Q44.798,24.918 44.798,24.624 Q44.798,24.302 44.938,24.092 Q45.078,23.882 45.295,23.742 Q45.512,23.602 45.778,23.546 Q46.044,23.49 46.31,23.49 Q46.716,23.49 47.059,23.56 Q47.402,23.63 47.668,23.798 Q47.934,23.966 48.095,24.26 Q48.256,24.554 48.284,25.002 L50.412,25.002 Q50.412,24.134 50.083,23.525 Q49.754,22.916 49.194,22.524 Q48.634,22.132 47.913,21.957 Q47.192,21.782 46.408,21.782 z",
      fillColor: "#198754",
      fillOpacity: 1.0,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(15, 30),
    }
  };
  senderAgentMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon:
    {
      path: "M12,2.016 Q14.906,2.016 16.945,4.055 Q18.984,6.094 18.984,9 Q18.984,10.453 18.257,12.328 Q17.53,14.203 16.499,15.844 Q15.468,17.485 14.46,18.914 Q13.452,20.343 12.749,21.187 L11.999,21.984 Q11.718,21.656 11.249,21.117 Q10.78,20.578 9.561,18.961 Q8.342,17.344 7.428,15.82 Q6.514,14.296 5.764,12.375 Q5.014,10.454 5.014,9 Q5.014,6.094 7.053,4.055 Q9.092,2.016 11.998,2.016 L12,2.016 z M8.831,4.941 Q8.399,4.941 7.967,5.058 Q7.535,5.175 7.193,5.418 Q6.851,5.661 6.639,6.026 Q6.428,6.39 6.428,6.885 Q6.428,7.326 6.594,7.637 Q6.761,7.947 7.031,8.154 Q7.301,8.361 7.643,8.492 Q7.985,8.622 8.345,8.712 Q8.696,8.811 9.038,8.892 Q9.38,8.973 9.65,9.081 Q9.92,9.189 10.086,9.351 Q10.253,9.513 10.253,9.774 Q10.253,10.017 10.127,10.175 Q10.001,10.332 9.812,10.422 Q9.623,10.512 9.407,10.544 Q9.191,10.575 9.002,10.575 Q8.723,10.575 8.462,10.508 Q8.201,10.44 8.007,10.301 Q7.814,10.161 7.697,9.936 Q7.58,9.711 7.58,9.387 L6.212,9.387 Q6.203,9.981 6.428,10.413 Q6.653,10.845 7.035,11.124 Q7.418,11.403 7.917,11.534 Q8.417,11.664 8.948,11.664 Q9.605,11.664 10.104,11.511 Q10.604,11.358 10.941,11.084 Q11.279,10.809 11.45,10.431 Q11.621,10.053 11.621,9.612 Q11.621,9.072 11.391,8.726 Q11.162,8.379 10.847,8.172 Q10.532,7.965 10.212,7.871 Q9.893,7.776 9.713,7.74 Q9.11,7.587 8.736,7.488 Q8.363,7.389 8.151,7.29 Q7.94,7.191 7.868,7.074 Q7.796,6.957 7.796,6.768 Q7.796,6.561 7.886,6.426 Q7.976,6.291 8.115,6.201 Q8.255,6.111 8.426,6.075 Q8.597,6.039 8.768,6.039 Q9.029,6.039 9.249,6.084 Q9.47,6.129 9.641,6.237 Q9.812,6.345 9.915,6.534 Q10.019,6.723 10.037,7.011 L11.405,7.011 Q11.405,6.453 11.193,6.062 Q10.982,5.67 10.622,5.418 Q10.262,5.166 9.798,5.054 Q9.335,4.941 8.831,4.941 z M15.662,5.094 L14.213,5.094 L11.783,11.52 L13.205,11.52 L13.709,10.089 L16.112,10.089 L16.598,11.52 L18.065,11.52 L15.662,5.094 z M14.933,6.678 L15.743,9.036 L14.078,9.036 L14.915,6.678 L14.933,6.678 z",
      fillColor: "#0DCAF0",
      fillOpacity: 1.0,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(15, 30),
    }
  };
  receiverMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      path: "M29.016,20.032 Q31.922,20.032 33.961,22.071 Q36,24.11 36,27.016 Q36,28.469 35.273,30.344 Q34.546,32.219 33.515,33.86 Q32.484,35.501 31.476,36.93 Q30.468,38.359 29.765,39.203 L29.015,40 Q28.734,39.672 28.265,39.133 Q27.796,38.594 26.577,36.977 Q25.358,35.36 24.444,33.836 Q23.53,32.312 22.78,30.391 Q22.03,28.47 22.03,27.016 Q22.03,24.11 24.069,22.071 Q26.108,20.032 29.014,20.032 L29.016,20.032 z M30.317,22.02 L24.927,22.02 L24.927,32.016 L27.125,32.016 L27.125,28.11 L29.323,28.11 Q30.149,28.11 30.513,28.474 Q30.877,28.838 30.989,29.622 Q31.073,30.224 31.115,30.882 Q31.157,31.54 31.339,32.016 L33.537,32.016 Q33.383,31.806 33.306,31.505 Q33.229,31.204 33.194,30.868 Q33.159,30.532 33.145,30.21 Q33.131,29.888 33.117,29.65 Q33.089,29.272 33.012,28.894 Q32.935,28.516 32.767,28.201 Q32.599,27.886 32.333,27.655 Q32.067,27.424 31.661,27.312 L31.661,27.284 Q32.501,26.948 32.872,26.304 Q33.243,25.66 33.243,24.778 Q33.243,24.204 33.04,23.707 Q32.837,23.21 32.452,22.832 Q32.067,22.454 31.528,22.237 Q30.989,22.02 30.317,22.02 z M29.533,23.728 Q30.289,23.728 30.667,24.057 Q31.045,24.386 31.045,25.114 Q31.045,25.87 30.667,26.206 Q30.289,26.542 29.533,26.542 L27.125,26.542 L27.125,23.728 L29.533,23.728 z",
      fillColor: "#DC3445",
      fillOpacity: 1.0,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(15, 30),
    }

  };
  receiverAgentMarkerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon:
    {
      path: "M63.609,6.802 Q66.515,6.802 68.554,8.841 Q70.593,10.88 70.593,13.786 C70.601,14.853 70.295,15.885 69.947,16.883 L67.6,10.606 L66.151,10.606 L63.721,17.032 L65.143,17.032 L65.647,15.601 L68.05,15.601 L68.536,17.032 L69.895,17.032 L69.866,17.114 Q69.139,18.989 68.108,20.63 Q67.077,22.271 66.069,23.7 Q65.061,25.129 64.358,25.973 L63.608,26.77 Q63.327,26.442 62.858,25.903 Q62.389,25.364 61.17,23.747 Q59.951,22.13 59.037,20.606 Q58.123,19.082 57.373,17.161 Q56.623,15.24 56.623,13.786 Q56.623,10.88 58.662,8.841 Q60.701,6.802 63.607,6.802 L63.609,6.802 z M61.363,10.606 L57.898,10.606 L57.898,17.032 L59.311,17.032 L59.311,14.521 L60.724,14.521 Q61.255,14.521 61.489,14.755 Q61.723,14.989 61.795,15.493 Q61.849,15.88 61.876,16.303 Q61.903,16.726 62.02,17.032 L63.433,17.032 Q63.334,16.897 63.284,16.703 Q63.235,16.51 63.212,16.294 Q63.19,16.078 63.181,15.871 Q63.172,15.664 63.163,15.511 Q63.145,15.268 63.095,15.025 Q63.046,14.782 62.938,14.579 Q62.83,14.377 62.659,14.228 Q62.488,14.08 62.227,14.008 L62.227,13.99 Q62.767,13.774 63.005,13.36 Q63.244,12.946 63.244,12.379 Q63.244,12.01 63.113,11.69 Q62.983,11.371 62.735,11.128 Q62.488,10.885 62.141,10.745 Q61.795,10.606 61.363,10.606 z M60.859,11.704 Q61.345,11.704 61.588,11.915 Q61.831,12.127 61.831,12.595 Q61.831,13.081 61.588,13.297 Q61.345,13.513 60.859,13.513 L59.311,13.513 L59.311,11.704 L60.859,11.704 z",
      fillColor: "#FFC107",
      fillOpacity: 1.0,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(15, 30),
    }
  };
  senderMarkerPosition?: google.maps.LatLngLiteral;
  senderAgentMarkerPosition?: google.maps.LatLngLiteral;
  receiverAgentMarkerPosition?: google.maps.LatLngLiteral;
  receiverMarkerPosition?: google.maps.LatLngLiteral;

  options: google.maps.MapOptions = {
    center: { lat: 40, lng: -20 },
    zoom: 4
  };

  constructor(private httpClient: HttpClient, private router: Router, private route: ActivatedRoute, private modalService: NgbModal, private locationService: LocationService, private wsService: WsService, private bookingsService: BookingsService, private userService: UserService, private authService: AuthService) {
    this.id = route.snapshot.params['id'];
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { bookings?: boolean, adminBookings?: boolean };
    if (state && state.bookings) {
      this.fromBookings = state.bookings;
    }
    if (state && state.adminBookings) {
      this.fromAdminBookings = state.adminBookings;
    }
    this.bookingsService.bookingsSubject.subscribe((bookings) => {
      console.log('BookingComponent got updated booking');
      for (let booking of bookings) {
        if (this.booking && booking.id == this.id) {
          this.booking.balance = booking.balance;
          this.booking.senderAgent = booking.senderAgent;
          if (booking.senderAgentDetail) {
            this.booking.senderAgentDetail = booking.senderAgentDetail;
            if (this.booking.senderAgentDetail && this.booking.senderAgentDetail.latitude && this.booking.senderAgentDetail.longitude) {
              this.senderAgentMarkerPosition = { lat: this.booking.senderAgentDetail.latitude, lng: this.booking.senderAgentDetail.longitude };
            }
          }
          this.booking.receiverAgent = booking.receiverAgent;
          if (booking.receiverAgentDetail) {
            this.booking.receiverAgentDetail = booking.receiverAgentDetail;
            if (this.booking.receiverAgentDetail && this.booking.receiverAgentDetail.latitude && this.booking.receiverAgentDetail.longitude) {
              this.receiverAgentMarkerPosition = { lat: this.booking.receiverAgentDetail.latitude, lng: this.booking.receiverAgentDetail.longitude };
            }
          }
          this.booking.receiver = booking.receiver;
          if (booking.receiverDetail) {
            this.booking.receiverDetail = booking.receiverDetail;
            if (this.booking.receiverDetail && this.booking.receiverDetail.latitude && this.booking.receiverDetail.longitude) {
              this.receiverMarkerPosition = { lat: this.booking.receiverDetail.latitude, lng: this.booking.receiverDetail.longitude };
            }
          }
          this.booking.cashPickedUp = booking.cashPickedUp;
          this.booking.cashDroppedOff = booking.cashDroppedOff;
          this.booking.complete = booking.complete;
        }
      }
    });
    this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
    });

    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBZrfOrOr1jgCFt5Mh-f4uMCfkDjARgUHI', 'callback').pipe(map(() => true), catchError(() => of(false)),);
  }

  ngOnInit(): void {
    //this.googleMap!.setMyLocationEnabled(true);
    this.route.paramMap.subscribe(params => {
      this.booking = null;
      this.id = params.get('id')!;
      this.httpClient.get('/api/bookings/' + this.id, {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.authService.token)
      }).subscribe((result: any) => {
        this.booking = result.booking;
        if (this.booking.senderLatitude && this.booking.senderLongitude) {
          this.senderMarkerPosition = { lat: this.booking.senderLatitude!, lng: this.booking.senderLongitude! };
        } else if (this.booking.senderDetail && this.booking.senderDetail.latitude && this.booking.senderDetail.longitude) {
          this.senderMarkerPosition = { lat: this.booking.senderDetail.latitude, lng: this.booking.senderDetail.longitude };
        }
        if (this.booking.senderAgentDetail && this.booking.senderAgentDetail.latitude && this.booking.senderAgentDetail.longitude) {
          this.senderAgentMarkerPosition = { lat: this.booking.senderAgentDetail.latitude, lng: this.booking.senderAgentDetail.longitude };
        }
        if (this.booking.receiverLatitude && this.booking.receiverLongitude) {
          this.receiverMarkerPosition = { lat: this.booking.receiverLatitude!, lng: this.booking.receiverLongitude! };
        } else if (this.booking.receiverDetail && this.booking.receiverDetail.latitude && this.booking.receiverDetail.longitude) {
          this.receiverMarkerPosition = { lat: this.booking.receiverDetail.latitude, lng: this.booking.receiverDetail.longitude };
        }
        if (this.booking.receiverAgentDetail && this.booking.receiverAgentDetail.latitude && this.booking.receiverAgentDetail.longitude) {
          this.receiverAgentMarkerPosition = { lat: this.booking.receiverAgentDetail.latitude, lng: this.booking.receiverAgentDetail.longitude };
        }
        if (this.booking.messages) {
          this.messages = this.booking.messages;
        }
        this.listenLocations();
      })
    });
  }

  listenLocations() {
    this.locationService.currentLocationSubject.asObservable().subscribe((location: Location) => {
      if (this.user && this.user.id && location.latitude && location.longitude) {
        if (this.user.id == this.booking.user) {
          this.center = { lat: location.latitude!, lng: location.longitude! };
          this.senderMarkerPosition = { lat: location.latitude!, lng: location.longitude! };
        } else if (this.user.id == this.booking.senderAgent) {
          this.center = { lat: location.latitude!, lng: location.longitude! };
          this.senderAgentMarkerPosition = { lat: location.latitude!, lng: location.longitude! };
        } else if (this.user.id == this.booking.receiverAgent) {
          this.center = { lat: location.latitude!, lng: location.longitude! };
          this.receiverAgentMarkerPosition = { lat: location.latitude!, lng: location.longitude! };
        } else if (this.user.id == this.booking.receiver) {
          this.center = { lat: location.latitude!, lng: location.longitude! };
          this.receiverMarkerPosition = { lat: location.latitude!, lng: location.longitude! };
        }
      }
    });

    this.wsService.observable.subscribe((message: any) => {
      //let message = JSON.parse(messageEvent.data);
      console.log("Received message " + JSON.stringify(message));
      if (message.type == 'message') {
        this.messages.push({ message: message.message });
      } else if (message.type == 'location') {
        if (message.id == this.user.id) {
          this.center = { lat: message.latitude, lng: message.longitude };
        }
        if (message.id == this.booking.user) {
          console.log('Message matches booking user');

          this.senderMarkerPosition = { lat: message.latitude, lng: message.longitude };
        }
        if (message.id == this.booking.senderAgent) {
          console.log('Message matches sender agent user');
          this.senderAgentMarkerPosition = { lat: message.latitude, lng: message.longitude };
        }
        if (message.id == this.booking.receiverAgent) {
          console.log('Message matches receiver agent user');

          this.receiverAgentMarkerPosition = { lat: message.latitude, lng: message.longitude };
        }
        if (message.id == this.booking.receiver) {
          console.log('Message matches receiver user');
          this.receiverMarkerPosition = { lat: message.latitude, lng: message.longitude };
        }
      }
    });
  }

  receiveCash() {
    let amount = this.booking.total;
    this.httpClient.post('/api/bookings/' + this.booking.id + '/receivecash', { amount: amount }, {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.authService.token)
    }).subscribe((result: any) => {

    });
  }

  cancelPickup() {
    this.modalService.open(BookingSenderagentcancelComponent).result.then((result) => {
      let reasonCode = result.code;
      let reasonMessage = result.message;
      //let amount = this.booking.receiverAmount;

      this.httpClient.post('/api/bookings/' + this.booking.id + '/offers/' + this.booking.senderAgentOffer + '/cancel', { role: 'SENDER', code: reasonCode, message: reasonMessage }, {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.authService.token)
      }).subscribe((result: any) => { });
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  giveCash() {
    this.modalService.open(BookingGivecashComponent).result.then((result) => {
      let code = `${result}`;
      let amount = this.booking.receiverAmount;

      this.httpClient.post('/api/bookings/' + this.booking.id + '/givecash', { amount: amount, code: code }, {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.authService.token)
      }).subscribe((result: any) => {

      });
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cancelDeliver() {
    this.modalService.open(BookingReceiveragentcancelComponent).result.then((result) => {
      let reasonCode = result.code;
      let reasonMessage = result.message;
      //let amount = this.booking.receiverAmount;
      this.httpClient.post('/api/bookings/' + this.booking.id + '/offers/' + this.booking.receiverAgentOffer + '/cancel', { role: 'RECEIVER', code: reasonCode, message: reasonMessage }, {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.authService.token)
      }).subscribe((result: any) => {

      });
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  cancelBooking() {
    this.modalService.open(BookingCancelComponent).result.then((result) => {
      let reasonCode = result.code;
      let reasonMessage = result.message;
      this.httpClient.post('/api/bookings/' + this.booking.id + '/cancel', { code: reasonCode, message: reasonMessage }, {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.authService.token)
      }).subscribe((result: any) => {

      });
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  back() {
    if (this.fromBookings) {
      this.router.navigate(['/bookings']);
    } else if (this.fromAdminBookings) {
      this.router.navigate(['/admin-bookings']);
    } else {
      this.router.navigate(['/']);
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      let text = this.formGroup.value.message;
      console.log('Message is ' + text);
      this.formGroup.setValue({ message: '' });

      let receiver;
      if (this.user.id == this.booking.sender) {
        receiver = this.booking.senderAgent;
      } else if (this.user.id == this.booking.receiver) {
        receiver = this.booking.receiverAgent;
      } else if (this.user.id == this.booking.senderAgent) {
        receiver = this.booking.sender;
      } else if (this.user.id == this.booking.receiverAgent) {
        receiver = this.booking.receiver;
      }

      let message = {
        action: 'sendmessage',
        booking: this.id,
        sender: this.user.id,
        receiver: receiver,
        message: text
      };

      this.wsService.subject.next(message);
    }
  }

  findAgents() {
    this.httpClient.post('/api/findagents', { id: this.booking.id }, {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.authService.token)
    }).subscribe((result: any) => {

    });
  }

  openRoleRating(role: string) {
    let service: string | undefined = undefined;
    if (role == 'SENDER') {
      service = 'submitsenderagentfeedback';
    } else if (role == 'RECEIVER') {
      service = 'submitreceiveragentfeedback';
    } else if (role == 'PICKUPAGENT') {
      service = 'submitsenderfeedback';
    } else if (role == 'DROPOFFAGENT') {
      service = 'submitreceiverfeedback'
    }
    if (service) {
      this.modalService.open(BookingFeedbackForRoleComponent).result.then((result) => {
        let rating = result.rating;
        let comment = result.comment;
        this.httpClient.post('/api/bookings/' + this.booking.id + '/'  + service, { rating: rating, comment: comment }, {
          headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.authService.token)
        }).subscribe((result: any) => {

        });
      }, (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else {
      alert('No role specified for feedback');
    }
  }

}
