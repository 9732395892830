<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Biller Categories</h2>
<table *ngIf="billerCategories" class="w-100">
    <tr>
        <th>Id</th>
        <th>Date</th>
        <th>Icon</th>
    </tr>
    <tr *ngFor="let category of billerCategories">
        <a (click)="openCategory(category);">{{ category.id }}</a>
        <td>{{ category.name }}</td>
        <td>{{ category.icon }}</td>
    </tr>
</table>
<div *ngIf="!billerCategories">
    Loading Biller Categories
</div>