import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-booking-feedback-for-role',
  templateUrl: './booking-feedback-for-role.component.html',
  styleUrls: ['./booking-feedback-for-role.component.scss']
})
export class BookingFeedbackForRoleComponent implements OnInit {

  rating = 0;

  form: UntypedFormGroup = new UntypedFormGroup({
    comment: new UntypedFormControl('')
  });

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  close() {
    let rating = this.rating;
    let comment = this.form.value.comment;
    this.activeModal.close({rating: rating, comment: comment});
  }

}
