<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Offers</h2>
<table *ngIf="offers">
    <tr>
        <th>Id</th>
        <th>Date</th>
        <th>Booking</th>
        <th>Role</th>
        <th>Status</th>
    </tr>
    <tr *ngFor="let offer of offers">
        <td>{{ offer.id }}</td>
        <td>{{ offer.date | date }}</td>
        <td><a [routerLink]="'/bookings/' + offer.booking">{{ offer.booking }}</a></td>
        <td>{{ offer.role }}</td>
        <td>
            <span *ngIf="!offer.accepted"><button (click)="acceptOffer(offer.id)">Accept</button></span>
            <span *ngIf="offer.assigned">Assigned</span>
            <span *ngIf="!offer.assigned && offer.accepted">Not Assigned</span>
        </td>
    </tr>
</table>
<div *ngIf="!offers">
    Loading Offers
</div>
