import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-send-scan',
  templateUrl: './send-scan.component.html',
  styleUrls: ['./send-scan.component.scss']
})
export class SendScanComponent implements OnInit {

  information?: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public scanSuccessHandler($event: any) {
    //this.scannerEnabled = false;
    this.information = "Espera recuperando información... ";

    console.log(JSON.stringify($event));

    this.activeModal.close($event);


    
    /*const appointment = new Appointment($event);
    this.logService.logAppointment(appointment).subscribe(
      (result: OperationResponse) => {
        this.information = $event;
        this.transports = result.object;
        this.cd.markForCheck();
      },
      (error: any) => {
        this.information = "Ha ocurrido un error por favor intentalo nuevamente ... ";
        this.cd.markForCheck();
      });
      */
  }


  close() {
    this.activeModal.close();
  }

}
