import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-create-biller-category',
  templateUrl: './create-biller-category.component.html',
  styleUrls: ['./create-biller-category.component.scss']
})
export class CreateBillerCategoryComponent implements OnInit {

  sent = false;

  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    icon: new UntypedFormControl('')
  });

  constructor(private httpClient: HttpClient, private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

  send() {
    let biller = {
      name: this.form.value.name,
      icon: this.form.value.icon
    };
    this.httpClient.post('/api/billercategories',biller, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.sent = true;
    });
  }

  back() {
    this.router.navigate(['/']);
  }

}
