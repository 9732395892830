import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, timer } from 'rxjs';
import { Booking } from '../models/booking';
import { User } from '../models/user';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { WsService } from './ws.service';

@Injectable({
  providedIn: 'root'
})
export class BookingsService {

  user?: User;

  public bookingsSubject = new BehaviorSubject<Booking[]>([] as Booking[]);
  public bookings = this.bookingsSubject.asObservable().pipe(distinctUntilChanged());
  
  constructor(private httpClient: HttpClient, private wsService: WsService, private userService: UserService, private authService: AuthService) { 
    this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
    });
    this.authService.isAuthenticated.subscribe(
      (authenticated) => {
        if (authenticated) {
          this.loadBookings();
        }
      }
    );

    //let ws = new WebSocket('wss://www.fastsend.com.ph/ws');
    //let ws = new WebSocket('https://localhost:4200/ws');
    //ws.onmessage = (messageEvent: MessageEvent) => {
    this.wsService.isConnected.subscribe((isConnected) => {
      if (isConnected) {
        console.log('Listening for bookings');
        this.wsService.observable.subscribe((message: any) => {
          //let message = JSON.parse(messageEvent.data);
          console.log("Bookings received message " + JSON.stringify(message));
          if (this.user) {
            if (message.type == 'booking') {
              if (message.user === this.user.id) {
                this.updateBooking(message);
              } else if (message.senderAgent === this.user.id) {
                this.updateBooking(message);
              } else if (message.receiverAgent === this.user.id) {
                this.updateBooking(message);
              } else if (message.receiver === this.user.id) {
                this.updateBooking(message);
              }
            }
          }
        });
      }
    });
  }

  loadBookings() {
    this.httpClient.get('/api/bookings?active=true', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.bookingsSubject.next(result);
    })
  }

  updateBooking(message: any) {
    console.log('Updating booking');
    let bookings = this.bookingsSubject.getValue();

    let found = false;
    for (let existingBooking of bookings) {
      if (existingBooking.id == message.id) {
        existingBooking.amount = message.amount;
        existingBooking.balance = message.balance;
        existingBooking.user = message.user;
        existingBooking.receiver = message.receiver;
        existingBooking.senderAgent = message.senderAgent;
        existingBooking.senderAgentDetail = message.senderAgentDetail;
        existingBooking.receiverAgent = message.receiverAgent;
        existingBooking.receiverAgentDetail = message.receiverAgentDetail;
        existingBooking.cashPickedUp = message.cashPickedUp;
        existingBooking.cashDroppedOff = message.cashDroppedOff;
        existingBooking.complete = message.complete;
        found = true;

        if (message.cashDroppedOff) {
          existingBooking['deleted'] = true;
          setInterval(() => {
            let deleteId = message.id;
            this.deleteBooking(deleteId);
            /*let bookings = this.bookingsSubject.getValue();
            for (let existingBooking of bookings) {
              if (existingBooking.id == deleteId) {
                existingBooking['deleted'] = true;
              }
            }*/
          }, 10000);
        }
      }
    }

    if (!found) {
      let booking = {id: message.id, name: message.name, amount: message.amount, user: message.user, receiver: message.receiver, senderAgent: message.senderAgent, recieverAgent: message.receiverAgent, code: message.code };
      bookings.unshift(booking);
    }

    this.bookingsSubject.next(bookings);
  }

  deleteBooking(id: string) {
    console.log('Deleting booking');
    let bookings = this.bookingsSubject.getValue();

    let found = false;
    let foundIndex = -1;
    let index = 0;
    for (let existingBooking of bookings) {
      if (existingBooking.id == id) {
        foundIndex = index;
        found = true;
      }
      index++;
    }

    if (found) {
      bookings.splice(foundIndex, 1);
    }

    this.bookingsSubject.next(bookings);
  }
}
