import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

  offers?: any;

  user?: any;

  constructor(private httpClient: HttpClient, private wsService: WsService, private router: Router, private route: ActivatedRoute, private userService: UserService, private authService: AuthService) { 
    this.offers = this.route.snapshot.data['offers'];
    /*this.bookingsService.bookingsSubject.subscribe((bookings) => {
      this.bookings = bookings;
    });*/
    this.userService.currentUserSubject.subscribe((user: any) => {
      this.user = user;
    });

    this.wsService.observable.subscribe((message: any) => {
      //let message = JSON.parse(messageEvent.data);
      console.log("Received message " + JSON.stringify(message));
      if (this.user) {
        if (message.type == 'offer') {
          if (message.agent === this.user.id) {
            this.updateOffer(message);
          }
        }
      }
    });

  }

  ngOnInit(): void {
    /*this.httpClient.get('/api/bookings', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result) => {
      this.bookings = result;
    })*/

    
  }

  updateOffer(message: any) {
    console.log('Updating offer');
    //let bookings = this.bookingsSubject.getValue();

    let found = false;
    for (let existingOffer of this.offers) {
      if (existingOffer.id == message.id) {
        existingOffer.accepted = message.accepted;
        existingOffer.agent = message.agent;
        existingOffer.booking = message.booking;
        existingOffer.role = message.role;
        existingOffer.date = message.date;
        found = true;
      }
    }

    if (!found) {
      let offer = { id: message.id, accepted: message.accepted, agent: message.agent, booking: message.booking, role: message.role, date: message.date };
      this.offers.unshift(offer);
    }

    //this.bookingsSubject.next(bookings);
  }

  acceptOffer(id: string) {
    let found = false;
    let offer;
    for (let anOffer of this.offers) {
      if (anOffer.id == id) {
        offer = anOffer;
        found = true;
      }
    }

    if (found) {
      this.httpClient.post('/api/bookings/' + offer.booking + '/offers/' + offer.id, {
        role: offer.role
      }, { headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)}).subscribe((result: any)=> {
        if (result.result == 0) {
          window.alert('Accepted');
        } else {
          window.alert('Denied');
        }
      })
    } else {
      console.log('Not found');
    }
  }

  back() {
    this.router.navigate(['/']);
  }

}
