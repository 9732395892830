<div>
    <button class="btn btn-primary" (click)="back()">&lt; Back</button>
    <h2>Create Biller</h2>
    <div *ngIf="!sent">
        <form [formGroup]="form" (ngSubmit)="send()">
            <div class="form-group">
                <label>Name</label>
                <input type="text" formControlName="name" class="form-control">
            </div>
            <div class="p-4 text-center">
                <input class="btn btn-primary" type="submit" value="Create Biller">
            </div>
        </form>
    </div>
    <div *ngIf="sent">
        Done
    </div>
</div>