<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Feedback for Role</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <form [formGroup]="form">
          <div class="form-group">
              <label>Rating</label>
              <ngb-rating [(rate)]="rating" [max]="5"></ngb-rating>
              
          </div>
          <div class="form-group">
            <label>Comment</label>
            <input type="text" formControlName="comment" class="form-control" >
        </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="close()">Submit Feedback</button>
  </div>