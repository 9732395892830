import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, ReplaySubject } from 'rxjs';
import { User } from '../models/user';
import { UserService } from './user.service';
import { WsService } from './ws.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token?: string;

  userId?: string;

  public isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  
  public authenticatedUserSubject = new BehaviorSubject<User>({} as User);
  public authenticatedUser = this.authenticatedUserSubject.asObservable().pipe(distinctUntilChanged());
  
  constructor(private httpClient: HttpClient) { 
    
  }

  login(login: string, password: string) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.httpClient.post('/api/login', { login: login, password: password }).subscribe((result: any) => {
        if (result.result !== undefined && result.result == 0) {
          console.log("Received result");
          this.token = result.token;
          this.userId = result.user.id;
          this.authenticatedUserSubject.next(result.user);
          this.isAuthenticatedSubject.next(true);
          
          localStorage.setItem('token', result.token!);
          localStorage.setItem('user', result.user);
          resolve(true);
        } else {
          console.log("Did not receive result");
          resolve(false);
        }
      });
    });
    
  }

  register(name: string, email: string, phone: string, password: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => { 
      this.httpClient.post('/api/register', { fullName: name, email: email, phone: phone, password: password }).subscribe((result: any) => {
        if (result.result !== undefined && result.result == 0) {
          console.log("Received result");
          this.token = result.token;
          this.userId = result.user.id;
          this.authenticatedUserSubject.next(result.user);
          this.isAuthenticatedSubject.next(true);
          
          localStorage.setItem('token', result.token!);
          localStorage.setItem('user', result.user);
          resolve(true);
        } else {
          console.log("Did not receive result");
          resolve(false);
        }
      });
    });
  }

  logout() {
    return new Promise<boolean>((resolve, reject) => { 
      this.authenticatedUserSubject.next({});
      this.isAuthenticatedSubject.next(false);
      this.userId = undefined;
      this.token = undefined;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      resolve(true);
    });
  }
}
