<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Payments</h2>
<table *ngIf="payments" class="w-100">
    <tr>
        <!--th>Id</th-->
        <th>Date</th>
        <th>Type</th>
        <th>Sender</th>
        <th>Receiver</th>
        <th>Amount</th>
        <th>Role</th>
        <th>Status</th>
    </tr>
    <tr *ngFor="let payment of payments" (click)="openPayment(payment)" style="cursor: pointer">
        <!--td><a [routerLink]="'/payments/' + payment.id">{{ payment.id }}</a></td-->
        <td>{{ payment.date | date }}</td>
        <td>{{ payment.type }}</td>
        <td>{{ payment.senderDetail?payment.senderDetail.fullName:payment.sender }}</td>
        <td>{{ payment.receiverDetail?payment.receiverDetail.fullName:payment.receiver }}</td>
        <td>{{ payment.amount | currency:payment.currency?payment.currency:'PHP' }}</td>
        <td style="text-align: right">
            <span *ngIf="payment.sender == user.id" class="badge bg-success">Sender</span>
            <span *ngIf="payment.receiver == user.id" class="badge bg-danger">Receiver</span>
        </td>
        <td>
            {{ payment.complete?'Completed':'' }}
            <button *ngIf="payment.type == 'PAYMENT_TO_BILLER' && payment.sender == user.id" (click)="pay(payment.id)">Pay</button>
        </td>
    </tr>
</table>
<div *ngIf="!payments">
    Loading Payments
</div>