<div>
    <button class="btn btn-primary" (click)="back()">&lt; Back</button>
    <h2>Cash-In with Agent Pick-up</h2>
    <div *ngIf="!sent">
        <form [formGroup]="form" (ngSubmit)="send()">
            <button *ngIf="user.agent" (click)="assistedBooking = !assistedBooking" type="button">Assisted Booking</button>
            <div [(ngbCollapse)]="!assistedBooking">
                <h3>Sender</h3>
                <div class="form-group">
                    <label>Email</label>
                    <input type="text" formControlName="senderEmail" class="form-control" (change)="getSenderByEmail()">
                    <span *ngIf="senderByEmail">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </span>
                </div>
                <div>-- OR --</div>
                <div class="form-group">
                    <label>Phone</label>
                    <input type="text" formControlName="senderPhone" class="form-control" (change)="getSenderByPhone()">
                    <span *ngIf="senderByPhone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </span>
                </div>
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" formControlName="senderName" class="form-control">
                </div>
            </div>
            <div class="form-group">
                <label>Amount</label>
                <input type="text" formControlName="amount" class="form-control">
            </div>
            <div class="form-group">
                <label>Currency</label>
                <select formControlName="currency" class="form-control">
                    <option *ngFor="let currency of currencies" [ngValue]="currency.symbol">{{currency.symbol}} - {{
                        currency.text }}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Location</label>
                <input type="text" formControlName="location" class="form-control">
            </div>
            <div class="p-4 text-center">
                <input class="btn btn-primary" type="submit" value="Cash-In">
            </div>
        </form>
    </div>
    <div *ngIf="sent">
        Sent
    </div>
</div>