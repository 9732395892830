import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-receive',
  templateUrl: './receive.component.html',
  styleUrls: ['./receive.component.scss']
})
export class ReceiveComponent implements OnInit {

  id!: string;

  constructor(private router: Router, private userService: UserService) { 
    this.id = this.userService.currentUserSubject.getValue().id!;
  }

  ngOnInit(): void {
  }

  back() {
    this.router.navigate(['/']);
  }

}
