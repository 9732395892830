import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-booking-cancel',
  templateUrl: './booking-cancel.component.html',
  styleUrls: ['./booking-cancel.component.scss']
})
export class BookingCancelComponent implements OnInit {


  form: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl(''),
    message: new UntypedFormControl('')
  });

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  close() {
    let code = this.form.value.code;
    let message = this.form.value.message;
    this.activeModal.close({code: code, message: message});
  }


}
