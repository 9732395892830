<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Support</h2>

<table *ngIf="support" class="w-100">
    <tr>
        <th>Date</th>
        <th>Type</th>
        <th>Status</th>
    </tr>
    <tr *ngFor="let item of support">
        <td>{{ item.email }}</td>
        <td>{{ item.message }}</td>
    </tr>
</table>
<div *ngIf="!support">
    Loading Support Messages
</div>