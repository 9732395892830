import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Currencies } from 'src/app/models/currencies';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-cash-in-agent',
  templateUrl: './cash-in-agent.component.html',
  styleUrls: ['./cash-in-agent.component.scss']
})
export class CashInAgentComponent implements OnInit {

  user?: any;
  sender?: string;
  senderByEmail = false;
  senderByPhone = false;

  assistedBooking = false;
  sent = false;

  currencies: {symbol:string, text: string}[];

  form: UntypedFormGroup = new UntypedFormGroup({
    senderPhone: new UntypedFormControl(''),
    senderEmail: new UntypedFormControl(''),
    senderName: new UntypedFormControl(''),
    amount: new UntypedFormControl(''),
    currency: new UntypedFormControl('PHP'),
    location: new UntypedFormControl('')
  });

  constructor(private httpClient: HttpClient, private router: Router, private userService: UserService, private authService: AuthService) {
    this.currencies = Currencies.currencies;
    this.userService.currentUserSubject.subscribe(
      (user) => {
        this.user = user;
        if (this.user.cash == undefined) {
          this.user.cash = 0;
        }
      }
    );
  }

  ngOnInit(): void {
  }

  getSenderByEmail() {
    let body = {
      email: this.form.value.senderEmail
    };
    this.httpClient.post('/api/getuserbyemail',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sender = result.user.id;
        this.senderByEmail = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          this.form.patchValue({senderName: result.user.fullName});
          
        //}
      } 
    });
  }

  getSenderByPhone() {
    let body = {
      phone: this.form.value.senderPhone
    };
    this.httpClient.post('/api/getuserbyphone',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sender = result.user.id;
        this.senderByPhone = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          this.form.patchValue({senderName: result.user.fullName});
        //}
      } 
    });
  }

  send() {
    let sender = this.user.id;

    if (this.assistedBooking) {
      sender = this.sender;
    }
    let booking: any = {
      type: 'CASH_IN',
      amount: this.form.value.amount,
      currency: this.form.value.currency,
      name: this.user.name,
      purpose: 'Cash-In',
      location: this.form.value.location,
      receiver: sender,
      sender: sender
    };

    if (this.assistedBooking) {
      booking.senderAgent = this.user.id;
    }
    
    this.httpClient.post('/api/bookings',booking, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.sent = true;
    });
  }

  back() {
    this.router.navigate(['/cash-in']);
  }

}
