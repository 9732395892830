<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Billers</h2>
<table *ngIf="billers" class="w-100">
    <tr>
        <th>Id</th>
        <th>Name</th>
    </tr>
    <tr *ngFor="let biller of billers">
        <td><a [routerLink]="'/billers/' + biller.id">{{ biller.id }}</a></td>
        <td>{{ biller.name }}</td>
    </tr>
</table>
<div *ngIf="!billers">
    Loading Billers
</div>