import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-cash-out-agent',
  templateUrl: './cash-out-agent.component.html',
  styleUrls: ['./cash-out-agent.component.scss']
})
export class CashOutAgentComponent implements OnInit {

  user?: any;

  sent = false;

  form: UntypedFormGroup = new UntypedFormGroup({
    amount: new UntypedFormControl(''),
    location: new UntypedFormControl('')
  });

  constructor(private httpClient: HttpClient, private router: Router, private userService: UserService, private authService: AuthService) {
    this.userService.currentUserSubject.subscribe(
      (user) => {
        this.user = user;
        if (this.user.cash == undefined) {
          this.user.cash = 0;
        }
      }
    );
  }

  ngOnInit(): void {
  }

  send() {
    let booking = {
      type: 'CASH_OUT',
      amount: this.form.value.amount,
      name: this.user.name,
      purpose: 'Cash-Out',
      location: this.form.value.location,
      receiver: this.user.id,
      sender: this.user.id
    };
    this.httpClient.post('/api/bookings',booking, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.sent = true;
    });
  }

  back() {
    this.router.navigate(['/cash-out']);
  }

}
