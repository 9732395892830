import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pay-bill-categories',
  templateUrl: './pay-bill-categories.component.html',
  styleUrls: ['./pay-bill-categories.component.scss']
})
export class PayBillCategoriesComponent implements OnInit {

  billerCategories?: any;

  constructor(private router: Router, private route: ActivatedRoute) { 
    this.billerCategories = this.route.snapshot.data['billerCategories'];
  }

  ngOnInit(): void {

  }

  openPayBill(category: any) {
    console.log('Clicked open category');
    this.router.navigate(['/pay-bill/' + category.name],{ state:{ category: category.id }});
  }

  back() {
    this.router.navigate(['/']);
  }

}
