<div>
    <button class="btn btn-primary" (click)="back()">&lt; Back</button>
    <h2>Create Super Agent</h2>
    <div *ngIf="!sent">
        <form [formGroup]="form" (ngSubmit)="create()">
            <div class="form-group">
                <label>Name</label>
                <input type="text" formControlName="name" class="form-control">
            </div>
            <div class="form-group">
                <label>Email</label>
                <input type="text" formControlName="email" class="form-control">
            </div>
            <div class="form-group">
                <label>Phone</label>
                <input type="text" formControlName="phone" class="form-control">
            </div>
            <div class="form-group">
                <label>Latitude</label>
                <input type="text" formControlName="latitude" class="form-control">
            </div>
            <div class="form-group">
                <label>Longitude</label>
                <input type="text" formControlName="longitude" class="form-control">
            </div>
            <div class="p-4 text-center">
                <input class="btn btn-primary" type="submit" value="Create Super Agent">
            </div>
        </form>
    </div>
    <div *ngIf="sent">
        Sent
    </div>
</div>