<div>
    <button class="btn btn-primary" (click)="back()">&lt; Back</button>
    <h2>Schedule Payment</h2>
    <div *ngIf="!sent">
        <form [formGroup]="form" (ngSubmit)="send()">
            <button *ngIf="user.agent" (click)="assistedPayment = !assistedPayment" type="button">Assisted Booking</button>
            <div [(ngbCollapse)]="assistedPayment">
                <h3>Sender</h3>
                <div class="form-group">
                    <label>Email</label>
                    <input type="text" formControlName="senderEmail" class="form-control" (change)="getSenderByEmail()">
                    <span *ngIf="senderByEmail">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </span>
                </div>
                <div>-- OR --</div>
                <div class="form-group">
                    <label>Phone</label>
                    <input type="text" formControlName="senderPhone" class="form-control" (change)="getSenderByPhone()">
                    <span *ngIf="senderByPhone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </span>
                </div>
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" formControlName="senderName" class="form-control">
                </div>
            </div>
            <h3>Receiver</h3>
            <div class="form-group">
                <label>Email</label>
                <input type="text" formControlName="email" class="form-control" (change)="getReceiverByEmail()">
                <span *ngIf="senderByEmail">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                </span>
            </div>
            -- OR --
            <div class="form-group">
                <label>Phone</label>
                <input type="text" formControlName="phone" class="form-control" (change)="getReceiverByPhone()">
                <span *ngIf="senderByPhone">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                </span>
            </div>
            <hr/>
            <div *ngIf="name" class="form-group">
                <label>Name</label>
                <div>{{ name }}</div>
            </div>
            <div class="form-group">
                <label>Type</label>
                <select formControlName="type" class="form-control">
                    <option ngValue="PAYMENT_TO_BILLER">Payment to Biller</option>
                    <option ngValue="PAYMENT_TO_PAYEE">Payment to Payee</option>
                </select>
            </div>
            <div class="form-group">
                <label>Reference Number</label>
                <input type="text" formControlName="referenceNumber" class="form-control">
            </div>
            <div class="form-group">
                <label>Amount</label>
                <input type="text" formControlName="amount" class="form-control">
            </div>
            <div class="form-group">
                <label>Payments</label>
                <input type="text" formControlName="intervals" class="form-control">
            </div>
            <div class="form-group">
                <label>Start Date</label>
                <div class="input-group mb-3">
                    <input type="text" ngbDatepicker class="form-control" #d="ngbDatepicker" (dateSelect)="onDateSelect($event)"/>
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">Edit</button>
                </div>
            </div>
            <div class="form-group">
                <label>Frequency</label>
                <div ngbDropdown class="input-group mb-3">
                    <input type="text" formControlName="intervalPeriod" class="form-control" aria-label="Text input with dropdown button">
                    <button ngbDropdownToggle class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{{ intervalUnit }}</button>
                    <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                      <li><a class="dropdown-item" (click)="setIntervalUnit('DAY')">Days</a></li>
                      <li><a class="dropdown-item" (click)="setIntervalUnit('WEEK')">Weeks</a></li>
                      <li><a class="dropdown-item" (click)="setIntervalUnit('MONTH')">Months</a></li>
                      <li><a class="dropdown-item" (click)="setIntervalUnit('YEAR')">Years</a></li>
                    </ul>
                </div>
            </div>
            <div class="p-4 text-center">
                <input class="btn btn-primary" type="submit" value="Schedule Payment">
            </div>
        </form>
    </div>
    <div *ngIf="sent">
        Sent
    </div>
</div>