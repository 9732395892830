<div>
    <button class="btn btn-primary" (click)="back()">&lt; Back</button>
    <h2>Receive</h2>
    <div class="d-flex flex-column align-items-center">
        <div>
            <qrcode [qrdata]="id" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
        </div>
        <div>{{ id }}</div>
    </div>
</div>
