import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-admin-support',
  templateUrl: './admin-support.component.html',
  styleUrls: ['./admin-support.component.scss']
})
export class AdminSupportComponent implements OnInit {

  support?: any;

  constructor(private httpClient: HttpClient, private router: Router, private wsService: WsService, private route: ActivatedRoute, private userService: UserService) { 
    this.support = this.route.snapshot.data['support'];
    /*this.bookingsService.bookingsSubject.subscribe((bookings) => {
      this.bookings = bookings;
    });*/
    /*this.userService.currentUserSubject.subscribe((user: any) => {
      this.user = user;
    });*/

    

  }

  ngOnInit(): void {
    /*this.httpClient.get('/api/bookings', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result) => {
      this.bookings = result;
    })*/

    
  }

  openUser(id: string) {
    this.router.navigate(['/admin-users/' + id]);
  }

  back() {
    this.router.navigate(['/']);
  }

}
