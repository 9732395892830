<button (click)="postKyc()">Post KYC</button>
<label class="image-upload-container btn btn-bwm">
    <span>Select Image</span>
    <input #imageInput
           type="file"
           accept="image/*"
           (change)="processFile(imageInput)">
</label>
<form [formGroup]="form" (submit)="submitKyc()">
    <div class="form-group">
        <label>Driver's License</label>
        <input type="text" formControlName="driverslicenseno" class="form-control">
    </div>
    <div class="p-4 text-center">
        <input type="submit">
    </div>
</form>