<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Photos</h2>
<div class="input-group">
    <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        [(ngModel)]="model"
        (ngModelChange)="loadPhotos()"
        ngbDatepicker
        #d="ngbDatepicker"
    />
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
</div>
<div *ngIf="photos && photos.length == 0">
    No Photos yet for this day!
</div>
<table *ngIf="photos && photos.length > 0" class="w-100">
    <tr>
        <th>Date</th>
        <th>Type</th>
        <th>User</th>
        <th>Status</th>
    </tr>
    <tr *ngFor="let photo of photos" (click)="openPhoto(photo)" style="cursor: pointer">
        <td>{{ photo.date | date }}</td>
        <td>{{ photo.type }}</td>
        <td>{{ photo.userDetail?photo.userDetail.fullName:'N/A' }}</td>
        <td><a [href]="bucket + photo.key ">{{ photo.key }}</a></td>
        <td>{{ photo.approved?'Approved':'' }}<button *ngIf="!photo.approved" (click)="approve(photo)">Approve</button></td>
    </tr>
</table>
<div *ngIf="!photos">
    Loading Photos
</div>