import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';
import {
  webSocket
} from 'rxjs/webSocket';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WsService {

  subject: any;
  observable: any;

  public isConnectedSubject = new BehaviorSubject<boolean>(false);
  public isConnected = this.isConnectedSubject.asObservable();
  
  constructor(private authService: AuthService) {
    this.authService.isAuthenticated.subscribe((value) => {
      if (value) {
        this.subject = webSocket({
          // production
          //url: 'wss://pev4uv03qh.execute-api.ap-southeast-1.amazonaws.com/Prod/?authorization=' + authService.token,
          // test
          url: environment.wsUrl + '?authorization=' + authService.token,
          // dev
          //url:'wss://65pcbou0i0.execute-api.us-west-1.amazonaws.com/Prod/?authorization=' + authService.token,
          closeObserver: {
            next: () => {
              console.log('[WsService]: connection closed');
            }
          },
        });
        this.observable = this.subject.asObservable();
        this.isConnectedSubject.next(true);
      }
    });
    
  }

}
