<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Set Cash On Hand</h2>
<form [formGroup]="form" (ngSubmit)="setCash()">
    <div class="form-group">
        <label>Currency</label>
        <select formControlName="currency" class="form-control" (change)="setCurrency($event)">
            <option *ngFor="let currency of currencies" [ngValue]="currency.symbol">{{ currency.text }}</option>
        </select>
    </div>
    <div class="form-group">
        <label>Amount</label>
        <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">&#8369;</span>
            <input type="text" formControlName="amount" class="form-control" aria-describedby="basic-addon1">
        </div>
    </div>
    <div class="p-4 text-center">
        <input class="btn btn-primary" type="submit" value="Set Cash On Hand">
    </div>
</form>
