import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BookingsService } from 'src/app/services/bookings.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-in-flight',
  templateUrl: './in-flight.component.html',
  styleUrls: ['./in-flight.component.scss']
})
export class InFlightComponent implements OnInit {

  isAuthenticated?: boolean;
  user: any;

  bookings?: any;

  constructor(private router: Router, private bookingsService: BookingsService, private userService: UserService, private authService: AuthService) { 
    this.authService.isAuthenticatedSubject.subscribe(
      (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      }
    )

    this.userService.currentUserSubject.subscribe(
      (user) => {
        this.user = user;
        if (this.user.cash == undefined) {
          this.user.cash = 0;
        }
      }
    );

    this.bookingsService.bookingsSubject.subscribe((bookings) => {
      console.log('AppComponent got updated bookings');
      this.bookings = bookings;
    });
  }
  
  ngOnInit(): void {
  }

  openBooking(booking: any) {
    this.router.navigate(['/bookings/' + booking.id]);
  }

}
