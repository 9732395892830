import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() events?: TimelineEvent[];

  constructor() { }

  ngOnInit(): void {
  }

}

class TimelineEvent {
  title?: string;
  active? = false;
  done? = false;
}
