import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService, private authService: AuthService, private router: Router, private http: HttpClient) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> | boolean {
    console.log('Checking auth state in auth guard');
    
    return this.checkLogin(next);;
  }

  checkLogin(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    if (this.authService.isAuthenticatedSubject.getValue()) {
      console.log('Already authenticated');
      return true;
    /*} else if (this.authService.needs2FA) {
      this.authService.redirectUrl = route.url.join('/');
      this.router.navigate(['/authenticate']);
      return false;*/
    } else {
      let token = localStorage.getItem('token');
      
      if (token) {
        console.log('Found token ' + token);
        this.authService.token = token;   

        let promise = new Promise<boolean>((resolve, reject) => {
          console.log('Fetching user info in auth guard');
          this.http.get('/api/user', { headers: new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', `Bearer ${this.authService.token}`) }).subscribe(
            (result: any) => {
              if (result.result == 0) {
                this.authService.userId = result.user.id;
                this.userService.currentUserSubject.next(result.user);
                this.authService.isAuthenticatedSubject.next(true);
                resolve(true);
              } else {
                resolve(true);
              }
              /*} else {
                this.authService.needs2FA = true;
                this.authService.user = result.user;
                this.authService.redirectUrl = route.url.join('/');
                this.router.navigate(['/authenticate']);
                resolve(false);
              }*/
            }, (error) => {
              this.authService.isAuthenticatedSubject.next(false);
              //this.authService.redirectUrl = route.url.join('/');
              resolve(true);
            }
          );
        });

        return promise;
      }

      console.log('Found no token ');
      //this.authService.redirectUrl = url;
      /*this.authService.redirectUrl = route.url.join('/');
      this.router.navigate(['/login']);*/
      return true;
    }
  }
}

