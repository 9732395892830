<div>   
    <button class="btn btn-primary" (click)="back()">&lt; Back</button>
    <div *ngIf="!sent">
        <h2>Send</h2>
        <ul ngbNav [(activeId)]="activeTab" #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem [ngbNavItem]="1">
                <a ngbNavLink>Details</a>
                <ng-template ngbNavContent>
                    <div class="mt-2">
                        <div class="btn-group" role="group" aria-label="Radio Buttons with Bootstrap 5">
                            <input type="radio" [formControl]="radio" value="EMAIL" class="btn-check" id="btnradio1" autocomplete="off">
                            <label class="btn btn-outline-primary" for="btnradio1">E-mail</label>
                            <input type="radio" [formControl]="radio" value="PHONE" class="btn-check" id="btnradio2" autocomplete="off">
                            <label class="btn btn-outline-primary" for="btnradio2">Phone</label>
                            <input type="radio" [formControl]="radio" value="ID" class="btn-check" id="btnradio3" autocomplete="off">
                            <label class="btn btn-outline-primary" for="btnradio3">Id</label>
                        </div>
                        <div *ngIf="radio.value == 'EMAIL'">
                            <form [formGroup]="emailForm" (ngSubmit)="getUserByEmail()">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" formControlName="email" class="form-control" placeholder="name@example.com" (ngModelChange)="updateSendDisabled()">
                                </div>
                                <div class="form-group">
                                    <label>Amount</label>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1">&#8369;</span>
                                        <input type="text" formControlName="amount" class="form-control" aria-describedby="basic-addon1" (ngModelChange)="updateSendDisabled()">
                                    </div>
                                </div>
                                <div class="p-4 text-center">
                                    <input class="btn btn-primary" type="submit" value="Send" [disabled]="sendDisabled">
                                </div>
                            </form>
                        </div>
                        <div *ngIf="radio.value == 'PHONE'">
                            <form [formGroup]="phoneForm" (ngSubmit)="getUserByPhone()">
                                <div class="form-group">
                                    <label>Phone</label>
                                    <input type="text" formControlName="phone" class="form-control" placeholder="09051234567" (ngModelChange)="updateSendDisabled()">
                                </div>
                                <div class="form-group">
                                    <label>Amount</label>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1">&#8369;</span>
                                        <input type="text" formControlName="amount" class="form-control" aria-describedby="basic-addon1" (ngModelChange)="updateSendDisabled()">
                                    </div>
                                </div>
                                <div class="p-4 text-center">
                                    <input class="btn btn-primary" type="submit" value="Send" [disabled]="sendDisabled">
                                </div>
                            </form>
                        </div>
                        <div *ngIf="radio.value == 'ID'">
                            <form [formGroup]="idForm" (ngSubmit)="getUserById()">
                                <div class="form-group">
                                    <label>Id</label>
                                    <div class="input-group mb-3">
                                        <input type="text" formControlName="id" class="form-control" (change)="updateSendDisabled()">
                                        <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="scan()">Scan</button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Amount</label>
                                    <div class="input-group mb-3">
                                        <span class="input-group-text" id="basic-addon1">&#8369;</span>
                                        <input type="text" formControlName="amount" class="form-control" aria-describedby="basic-addon1" (change)="updateSendDisabled()">
                                    </div>
                                </div>
                                <div class="p-4 text-center">
                                    <input class="btn btn-primary" type="submit" value="Send" [disabled]="sendDisabled">
                                </div>
                            </form>
                        </div>
                    </div>
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="2" [disabled]="disableTab2">
                <a ngbNavLink>Confirmation</a>
                <ng-template ngbNavContent>
                    <h1>Confirmation</h1>
                    <form [formGroup]="confirmForm" (ngSubmit)="send()">
                        <!--div class="form-group">
                            <label>Id</label>
                            <input type="text" formControlName="id" class="form-control">
                        </div>
                        <div class="form-group">
                            <label>Amount</label>
                            <input type="text" formControlName="amount" class="form-control">
                        </div-->
                        <div class="d-flex flex-column align-items-center">
                            <div>Name: {{ sendName }}</div>
                            <div>Amount: {{ sendAmount | currency:"PHP" }}</div>
                            <div class="p-4 text-center">
                                <input class="btn btn-primary" type="submit" value="Confirm">
                            </div>
                        </div>
                    </form>
                        
                </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="3" [disabled]="disableTab3">
                <a ngbNavLink>Sent</a>
                <ng-template ngbNavContent>
                    <h1>Sent</h1>
                    <div class="d-flex flex-column align-items-center">
                        <div>Name: {{ sendName }}</div>
                        <div>Amount: {{ sendAmount | currency:"PHP" }}</div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>

        
        <!--form [formGroup]="form" (ngSubmit)="send()">
            <div class="form-group">
                <label>Name</label>
                <input type="text" formControlName="name" class="form-control">
            </div>
            <div class="form-group">
                <label>Age</label>
                <input type="text" formControlName="age" class="form-control">
            </div>
            <div class="form-group">
                <label>Amount</label>
                <input type="text" formControlName="amount" class="form-control">
            </div>
            <div class="form-group">
                <label>Purpose</label>
                <input type="text" formControlName="purpose" class="form-control">
            </div>
            <div class="form-group">
                <label>Location</label>
                <input type="text" formControlName="location" class="form-control">
            </div>
            <div class="p-4 text-center">
                <input type="submit">
            </div>
        </form-->
    </div>
    <div *ngIf="sent">
        Sent
    </div>