import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Currencies } from 'src/app/models/currencies';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-set-cash',
  templateUrl: './set-cash.component.html',
  styleUrls: ['./set-cash.component.scss']
})
export class SetCashComponent implements OnInit {

  currency = 'PHP';

  currencies: {symbol: string, text: string}[];

  form: UntypedFormGroup = new UntypedFormGroup({
    currency: new UntypedFormControl('PHP'),
    amount: new UntypedFormControl('0')
  });

  constructor(private httpClient: HttpClient, private router: Router, private userService: UserService, private authService: AuthService) { 
    this.currencies = Currencies.currencies;
  }

  ngOnInit(): void {
  }

  setCurrency(event: any) {
    this.currency = this.form.value.currency;
  }

  setCash() {
    let body = {
      cash: +this.form.value.amount,
      currency: this.form.value.currency
    };
    this.httpClient.post('/api/setcash', body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      //if (result.result === 0) {
        let user = this.userService.currentUserSubject.getValue();
        user.cash = result.cash;
        this.userService.currentUserSubject.next(user);
      //}
    });
  }

  back() {
    this.router.navigate(['/']);
  }

}
