<div class="modal-header">
  <h4 class="modal-title">Agent Offer</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss(false)"></button>
</div>
<div class="modal-body">
  <p>Offer: {{ offer.id }}</p>
  <p>Booking: {{ offer.booking }}</p>
  <p>Role: {{ offer.role }}</p>
  <p>Amount: {{ offer.amount }}</p>
  <p>Would you like to accept the booking?</p>
  <p *ngIf="message">{{ message }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="acceptOffer()">Accept</button>
  <button type="button" class="btn btn-outline-dark" (click)="declineOffer()">Decline</button>
</div>