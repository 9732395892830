<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Booking Cancellation</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
      <form [formGroup]="form">
          <div class="form-group">
              <label>Reason Code</label>
              <input type="text" formControlName="code" class="form-control" >
          </div>
          <div class="form-group">
            <label>Reason Message</label>
            <input type="text" formControlName="message" class="form-control" >
        </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="close()">Cancel Booking</button>
  </div>