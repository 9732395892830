import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { Location } from '../models/location';
import { AuthService } from './auth.service';
import { WsService } from './ws.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  user?: User;

  public currentLocationSubject = new BehaviorSubject<Location>({} as Location);

  constructor(private wsService: WsService, private userService: UserService, private authService: AuthService) { 
    this.userService.currentUserSubject.subscribe((user: User) => {
      this.user = user;
    });
    this.authService.isAuthenticated.subscribe(
      (authenticated) => {
        if (authenticated) {
          this.startLocating();
        }
      }
    );
  }

  startLocating() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('Position reported as ' + JSON.stringify(position));
        if (position.coords) {
          let location: Location = { latitude: position.coords!.latitude, longitude: position.coords!.longitude};
          this.currentLocationSubject.next(location);
          let message = {
            action: 'sendlocation',
            id: this.user!.id,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          console.log('Sending message ' + JSON.stringify(message));
          this.wsService.subject.next(message);
        }
      });

      //this.isTracking = true;
      navigator.geolocation.watchPosition((position) => {
        console.log('Position reported as ' + JSON.stringify(position));
        if (position.coords) {
          let message = {
            action: 'sendlocation',
            id: this.user!.id,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          console.log('Sending message ' + JSON.stringify(message));
          this.wsService.subject.next(message);
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
}
