<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Admin Cash-Out</h2>
<div *ngIf="!sent">
    <div class="btn-group" role="group" aria-label="Radio Buttons with Bootstrap 5">
        <input type="radio" [formControl]="radio" value="EMAIL" class="btn-check" id="btnradio1" autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio1">E-mail</label>
        <input type="radio" [formControl]="radio" value="PHONE" class="btn-check" id="btnradio2" autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio2">Phone</label>
        <input type="radio" [formControl]="radio" value="ID" class="btn-check" id="btnradio3" autocomplete="off">
        <label class="btn btn-outline-primary" for="btnradio3">Id</label>
    </div>

    <div *ngIf="radio.value == 'EMAIL'">
        <form [formGroup]="emailForm">
            <div class="form-group">
                <label>Email</label>
                <input type="email" formControlName="email" class="form-control" placeholder="name@example.com"
                    (change)="getUserByEmail()">
            </div>
        </form>
    </div>
    <div *ngIf="radio.value == 'PHONE'">
        <form [formGroup]="phoneForm">
            <div class="form-group">
                <label>Phone</label>
                <input type="text" formControlName="phone" class="form-control" placeholder="09051234567"
                    (change)="getUserByPhone()">
            </div>
        </form>
    </div>
    <div *ngIf="radio.value == 'ID'">
        <form [formGroup]="idForm">
            <div class="form-group">
                <label>Id</label>
                <input type="text" formControlName="id" class="form-control" (change)="getUserById()">
            </div>
        </form>
    </div>
    <div *ngIf="receiver" class="form-group">
        <label>Receiver</label>
        <div>{{ name }}</div>
        <div>{{ receiver }}</div>
        <!--input type="text" formControlName="receiver" class="form-control"-->
    </div>
    <form [formGroup]="form" (ngSubmit)="cashIn()">
        <div class="form-group">
            <label>Currency</label>
            <select formControlName="currency" class="form-control" (change)="setCurrency($event)">
                <option *ngFor="let currency of currencies" [ngValue]="currency.symbol">{{ currency.symbol }} - {{
                    currency.text }}</option>
            </select>
        </div>
        <div class="form-group">
            <label>Amount</label>
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">
                    <span *ngIf="currency=='PHP'">&#8369;</span>
                    <span *ngIf="currency=='USD'">$</span>
                </span>
                <input type="text" formControlName="amount" class="form-control" aria-describedby="basic-addon1">
            </div>
        </div>
        <div class="form-group">
            <label>Type</label>
            <select formControlName="type" class="form-control">
                <option *ngFor="let type of types" [ngValue]="type.id">{{ type.label }}</option>
            </select>
        </div>
        <div class="form-group">
            <label>Reference</label>
            <input type="text" formControlName="reference" class="form-control">
        </div>
        <div class="p-4 text-center">
            <input class="btn btn-primary" type="submit" value="Cash-Out">
        </div>
    </form>
</div>
<div *ngIf="sent">
    Sent!
</div>