<h1>Agent Information</h1>
<div *ngIf="isLoggedIn">
    <p>To become an agent or outlet you only need to submit the form below.</p>
    <!--button (click)="becomeAnAgent()">Become An Agent</button-->

    <form [formGroup]="form" (ngSubmit)="setAgent()">
        <div class="form-group">
            <label>Type</label>
            <select formControlName="type" class="form-control">
                <!--option *ngIf="!payment" ngValue="CASH_TO_CASH">Cash To Cash</option-->
                <option ngValue="STATIONARY">Outlet</option>
                <!--option *ngIf="!payment" ngValue="WALLET_TO_CASH">E-Wallet to Cash</option-->
                <option ngValue="ROAMING">Agent</option>
            </select>
        </div>
        <div class="form-group">
            <label>Name</label>
            <input type="text" formControlName="name" class="form-control">
        </div>
        <!--div class="form-group">
            <label>Email</label>
            <input type="text" formControlName="email" class="form-control">
        </div>
        <div class="form-group">
            <label>Phone</label>
            <input type="text" formControlName="phone" class="form-control">
        </div-->
        <div class="form-group">
            <label>Latitude</label>
            <input type="text" formControlName="latitude" class="form-control">
        </div>
        <div class="form-group">
            <label>Longitude</label>
            <input type="text" formControlName="longitude" class="form-control">
        </div>
        <div class="p-4 text-center">
            <input type="submit">
        </div>
    </form>

    <button (click)="pauseAgent()">Pause</button>
    <button (click)="unPauseAgent()">Un-Pause</button>
    <form [formGroup]="superagentform" (ngSubmit)="setSuperAgent()">
        <div class="form-group">
            <label>Superagent</label>
            <input type="text" formControlName="superagent" class="form-control">
        </div>
        <div class="p-4 text-center">
            <input type="submit">
        </div>
    </form>
</div>