import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-cash',
  templateUrl: './cash.component.html',
  styleUrls: ['./cash.component.scss']
})
export class CashComponent implements OnInit {

  isAuthenticated?: boolean;
  user: any;

  cashOnHand?: any[];

  constructor(private router: Router, private userService: UserService, private authService: AuthService) { 
    this.authService.isAuthenticatedSubject.subscribe(
      (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      }
    )

    this.userService.currentUserSubject.subscribe(
      (user) => {
        this.user = user;
        this.cashOnHand = Object.keys(this.user.cashOnHand).map(key => ({currency: key, amount: this.user.cashOnHand[key]}));
        if (this.user.cash == undefined) {
          this.user.cash = 0;
        }
      }
    );
  }

  ngOnInit(): void {
  }

  setCash() {
    this.router.navigate(['/setcash']);
  }

}
