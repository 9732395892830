import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-booking-givecash',
  templateUrl: './booking-givecash.component.html',
  styleUrls: ['./booking-givecash.component.scss']
})
export class BookingGivecashComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({
    code: new UntypedFormControl('')
  });

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
  }

  close() {
    let code = this.form.value.code;
    this.activeModal.close(code);
  }

  /*private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }*/

}
