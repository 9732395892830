import { Component, OnInit } from '@angular/core';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-selfie',
  templateUrl: './selfie.component.html',
  styleUrls: ['./selfie.component.scss']
})
export class SelfieComponent implements OnInit {

  constructor(private imageService: ImageService) { }

  ngOnInit(): void {
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    let mimeType = file.type;
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      //this.selectedFile = new ImageSnippet(event.target.result, file);
      var buffer = this.dataUriToBlob(event.target.result);
      this.imageService.uploadPhoto(mimeType, buffer).then(
        (res) => {
        
        },
        (err) => {
        
        })
    });

    reader.readAsDataURL(file);
    //reader.readAsArrayBuffer(file);
  }

  private dataUriToBlob(dataUri: string): Blob {
    var byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataUri.split(',')[1]);
    else
        byteString = unescape(dataUri.split(',')[1]);

    // separate out the mime component
    var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }


}
