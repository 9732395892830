<div>
    <button class="btn btn-primary" (click)="back()">&lt; Back</button>
    <h2>Cash-Out with Agent Drop-off</h2>
    <div *ngIf="!sent">
        <form [formGroup]="form" (ngSubmit)="send()">
            <div class="form-group">
                <label>Amount</label>
                <input type="text" formControlName="amount" class="form-control">
            </div>
            <div class="form-group">
                <label>Location</label>
                <input type="text" formControlName="location" class="form-control">
            </div>
            <div class="p-4 text-center">
                <input class="btn btn-primary" type="submit" value="Cash-Out">
            </div>
        </form>
    </div>
    <div *ngIf="sent">
        Sent
    </div>
</div>