import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  isAuthenticated?: boolean;
  user: any;

  constructor(private userService: UserService, private authService: AuthService) { 
    this.authService.isAuthenticatedSubject.subscribe(
      (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      }
    )

    this.userService.currentUserSubject.subscribe(
      (user) => {
        this.user = user;
        if (this.user.cash == undefined) {
          this.user.cash = 0;
        }
      }
    );
  }

  ngOnInit(): void {
  }

  

}
