import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ImageService } from 'src/app/services/image.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-admin-photos',
  templateUrl: './admin-photos.component.html',
  styleUrls: ['./admin-photos.component.scss']
})
export class AdminPhotosComponent implements OnInit {

  model?: NgbDateStruct;
  
  photos?: any;

  user?: any;

  bucket?: string;

  constructor(private httpClient: HttpClient, private router: Router, private wsService: WsService, private route: ActivatedRoute, private userService: UserService, private authService: AuthService) { 
    this.bucket = ImageService.photoBucket;
    let date = new Date();
    this.model = {year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};
    this.photos = this.route.snapshot.data['photos'];
    /*this.bookingsService.bookingsSubject.subscribe((bookings) => {
      this.bookings = bookings;
    });*/
    this.userService.currentUserSubject.subscribe((user: any) => {
      this.user = user;
    });

    /*this.wsService.observable.subscribe((message: any) => {
      //let message = JSON.parse(messageEvent.data);
      console.log("Received message " + JSON.stringify(message));
      if (this.user) {
        if (message.type == 'payment') {
          if (message.sender === this.user.id || message.receiver == this.user.id) {
            //this.updatePayment(message);
          }
        }
      }
    });*/

  }

  ngOnInit(): void {
    /*this.httpClient.get('/api/bookings', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result) => {
      this.bookings = result;
    })*/

    
  }

  loadPhotos() {
    if (this.model) {
      let year = this.model.year;
      let month = this.model.month>9?this.model.month:'0'+this.model.month;
      let day = this.model.day>9?this.model.day:'0'+this.model.day;
      this.httpClient.get('/api/admin/photos?ymd=' + year + '-' + month + '-' + day, {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((results) => {
        this.photos = results;
      });
    }
  }

  openPhoto(id: string) {
    this.router.navigate(['/photos/' + id],{ state:{ adminPayments: true }});
  }

  /*updatePayment(message: any) {
    console.log('Updating payment');

    let found = false;
    for (let existingPayment of this.payments) {
      if (existingPayment.id == message.id) {
        existingPayment.sender = message.sender;
        existingPayment.receiver = message.receiver;
        existingPayment.type = message.type;
        existingPayment.date = message.date;
        found = true;
      }
    }

    if (!found) {
      let payment = { id: message.id, type: message.type, sender: message.sender, receiver: message.receiver, amount: message.amount, date: message.date };
      this.payments.unshift(payment);
    }
  }*/

  back() {
    this.router.navigate(['/']);
  }

  approve(photo: any) {
    this.httpClient.post('/api/photos/' + photo.userId + '!' + photo.id, {approved:true},  {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result)=> {
      //this.router.navigate(['/kycs']);
    });
  }

}
