import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-create-kyc',
  templateUrl: './create-kyc.component.html',
  styleUrls: ['./create-kyc.component.scss']
})
export class CreateKycComponent  {

  id?: string;

  form: UntypedFormGroup = new UntypedFormGroup({
    driverslicenseno: new UntypedFormControl('')
  });
  
  constructor(private imageService: ImageService, private httpClient: HttpClient, private authService: AuthService){}

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    let mimeType = file.type;
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      //this.selectedFile = new ImageSnippet(event.target.result, file);
      var buffer = this.dataUriToBlob(event.target.result);
      this.imageService.uploadImage(this.id, mimeType, buffer).then(
        (res) => {
        
        },
        (err) => {
        
        })
    });

    reader.readAsDataURL(file);
    //reader.readAsArrayBuffer(file);
  }

  private dataUriToBlob(dataUri: string): Blob {
    var byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataUri.split(',')[1]);
    else
        byteString = unescape(dataUri.split(',')[1]);

    // separate out the mime component
    var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

  postKyc() {
    let kyc = {
      termsandconditions: 'yep'
    };
    this.httpClient.post('/api/kyc',kyc, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      console.log(result.id);
      this.id = result.id;
    });
  }
  
  submitKyc() {
    let kyc = {
      driverslicenseno: this.form.value.driverslicenseno,
      id: this.id
    };
    this.httpClient.post('/api/submitkyc',kyc, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {

    });
  }

}
