import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-booking-agent',
  templateUrl: './booking-agent.component.html',
  styleUrls: ['./booking-agent.component.scss']
})
export class BookingAgentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
