import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit {

  user: any;

  name?: string;
  intervalUnit = 'MONTH';
  startDate = new Date();
  billerId?: string;
  sender?: string;
  receiver?: string;
  senderByEmail = false;
  senderByPhone = false;
  receiverByEmail = false;
  receiverByPhone = false;

  assistedPayment = false;
  sent = false;

  form: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl('PAYMENT_TO_BILLER'),
    referenceNumber: new UntypedFormControl(''),
    intervals: new UntypedFormControl('1'),
    intervalPeriod: new UntypedFormControl('1'),
    intervalUnit: new UntypedFormControl('MONTH'),

    email: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    amount: new UntypedFormControl('')
  });

  constructor(private httpClient: HttpClient, private router: Router, private userService: UserService, private authService: AuthService) { 
    this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
  }

  setIntervalUnit(intervalUnit: string) {
    this.intervalUnit = intervalUnit;
  }

  onDateSelect(event: any) {

  }

  getSenderByEmail() {
    let body = {
      email: this.form.value.email
    };
    this.httpClient.post('/api/getuserbyemail',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sender = result.user.id;
        this.senderByEmail = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          //this.form.patchValue({name: result.user.fullName});
          this.name = result.user.fullName;
        //}
      } 
    });
  }

  getSenderByPhone() {
    let body = {
      phone: this.form.value.phone
    };
    this.httpClient.post('/api/getuserbyphone',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sender = result.user.id;
        this.senderByPhone = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          //this.form.patchValue({name: result.user.fullName});
          this.name = result.user.fullName;
        //}
      } 
    });
  }

  getReceiverByEmail() {
    let body = {
      email: this.form.value.email
    };
    this.httpClient.post('/api/getuserbyemail',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sender = result.user.id;
        this.senderByEmail = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          //this.form.patchValue({name: result.user.fullName});
          this.name = result.user.fullName;
        //}
      } 
    });
  }

  getReceiverByPhone() {
    let body = {
      phone: this.form.value.phone
    };
    this.httpClient.post('/api/getuserbyphone',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.receiver = result.user.id;
        this.receiverByPhone = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          //this.form.patchValue({name: result.user.fullName});
          this.name = result.user.fullName;
        //}
      } 
    });
  }

  send() {
    let payment = {
      type: this.form.value.type,
      email: this.form.value.email,
      phone: this.form.value.phone,
      amount: this.form.value.amount,
      sender: this.sender,
      startDate: this.startDate.toISOString(),
      intervals: this.form.value.intervals,
      intervalPeriod: this.form.value.intervalPeriod,
      intervalUnit: this.intervalUnit
    };
    this.httpClient.post('/api/payments',payment, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.sent = true;
    });
  }

  back() {
    this.router.navigate(['/']);
  }

}
