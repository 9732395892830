<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Bookings</h2>
<table *ngIf="bookings" class="w-100">
    <tr>
        <!--th>Id</th-->
        <th>Date</th>
        <th>Sender</th>
        <th>Receiver</th>
        <th>Amount</th>
        <th>Role</th>
    </tr>
    <tr *ngFor="let booking of bookings" (click)="openBooking(booking.id)">
        <!--td><a (click)="openBooking(booking.id)">{{ booking.id }}</a></td-->
        <td>{{ booking.date | date }}</td>
        <td>{{ booking.senderDetail.fullName }}</td>
        <td>{{ booking.receiverDetail.fullName }}</td>
        <!--td>{{ booking.name }}</td-->
        <td style="text-align: right">{{ booking.amount | currency:booking.currency }}</td>
        <td style="text-align: right">
            <span *ngIf="booking.user != booking.sender && booking.user == user.id" class="badge bg-success">Creator</span>
            <span *ngIf="booking.sender == user.id" class="badge bg-success">Sender</span>
            <span *ngIf="booking.senderAgent == user.id" class="badge bg-info">Sender Agent</span>
            <span *ngIf="booking.receiverAgent == user.id" class="badge bg-warning">Receiver Agent</span>
            <span *ngIf="booking.receiver == user.id" class="badge bg-danger">Receiver</span>
        </td>
        <td>
            {{ booking.complete?'Complete':'' }}
            {{ booking.cancelled?'Cancelled':'' }}
        </td>
    </tr>
</table>
<div *ngIf="!bookings">
    Loading Bookings
</div>
