import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-biller-categories',
  templateUrl: './biller-categories.component.html',
  styleUrls: ['./biller-categories.component.scss']
})
export class BillerCategoriesComponent implements OnInit {

  billerCategories?: any;

  constructor(private router: Router, private route: ActivatedRoute) { 
    this.billerCategories = this.route.snapshot.data['billerCategories'];
  }

  ngOnInit(): void {

  }

  openCategory(category: any) {
    console.log('Clicked open category');
    this.router.navigate(['/billers/' + category.name],{ state:{ category: category.id }});
  }

  back() {
    this.router.navigate(['/']);
  }

}
