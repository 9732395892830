import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Currencies } from 'src/app/models/currencies';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-create-booking',
  templateUrl: './create-booking.component.html',
  styleUrls: ['./create-booking.component.scss']
})
export class CreateBookingComponent implements OnInit {

  user: any;

  currencies: {symbol:string, text: string}[];
  sender?: string;
  receiver?: string;
  amount?: number;
  payment?: string;
  receiverByEmail = false;
  receiverByPhone = false;
  senderByEmail = false;
  senderByPhone = false;
  
  assistedBooking = false;
  sent = false;
  sentConfirmation = false;

  response?: any;

  form: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl('CASH_TO_CASH'),
    senderPhone: new UntypedFormControl(''),
    senderEmail: new UntypedFormControl(''),
    senderName: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    age: new UntypedFormControl(''),
    amount: new UntypedFormControl(''),
    currency: new UntypedFormControl('PHP'),
    purpose: new UntypedFormControl(''),
    location: new UntypedFormControl('')
  });

  updateBookingForm: UntypedFormGroup = new UntypedFormGroup({
    senderRadius: new UntypedFormControl('2500'),
    receiverRadius: new UntypedFormControl('2500')
  });

  constructor(private httpClient: HttpClient, private router: Router, private userService: UserService, private authService: AuthService) {
    this.currencies = Currencies.currencies;
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { sender?: string, receiver?: string, amount?: number, payment?: string};
    if (state) {
      this.sender = state.sender;
      this.receiver = state.receiver;
      this.amount = state.amount;
      this.payment = state.payment;
      this.form.patchValue({type: 'CASH_TO_WALET'});
    } else {
      this.sender = this.authService.userId;
    }
    this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
  }

  getReceiverByEmail() {
    let body = {
      email: this.form.value.email
    };
    this.httpClient.post('/api/getuserbyemail',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.receiver = result.user.id;
        this.receiverByEmail = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          this.form.patchValue({name: result.user.fullName});
          
        //}
      } 
    });
  }

  getReceiverByPhone() {
    let body = {
      phone: this.form.value.phone
    };
    this.httpClient.post('/api/getuserbyphone',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.receiver = result.user.id;
        this.receiverByPhone = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          this.form.patchValue({name: result.user.fullName});
        //}
      } 
    });
  }

  getSenderByEmail() {
    let body = {
      email: this.form.value.senderEmail
    };
    this.httpClient.post('/api/getuserbyemail',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sender = result.user.id;
        this.senderByEmail = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          this.form.patchValue({senderName: result.user.fullName});
          
        //}
      } 
    });
  }

  getSenderByPhone() {
    let body = {
      phone: this.form.value.senderPhone
    };
    this.httpClient.post('/api/getuserbyphone',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sender = result.user.id;
        this.senderByPhone = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          this.form.patchValue({senderName: result.user.fullName});
        //}
      } 
    });
  }

  sendBooking() {
    let sender = this.user.id;

    if (this.assistedBooking) {
      sender = this.sender;
    }
    let booking: any = {
      type: this.form.value.type,
      email: this.form.value.email,
      phone: this.form.value.phone,
      name: this.form.value.name,
      age: this.form.value.age,
      amount: this.amount?this.amount:this.form.value.amount,
      currency: this.amount?'PHP':this.form.value.currency,
      purpose: this.form.value.purpose,
      location: this.form.value.location,
      receiver: this.receiver,
      receiverCurrency: 'PHP',
      sender: sender,
      payment: this.payment,
      confirmation: false
    };
    if (this.assistedBooking) {
      booking.senderAgent = this.user.id;
    }
    this.httpClient.post('/api/bookings',booking, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.response = result;
      this.sent = true;
    });
  }

  sendConfirmation() {
    this.httpClient.post('/api/bookings/' + this.response.id ,{confirmation: true}, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.router.navigate(['/bookings/' + this.response.id]);
      //this.sentConfirmation = true;
    });
  }

  updateBooking() {
    this.httpClient.post('/api/bookings/' + this.response.id ,{
      senderRadius: +this.updateBookingForm.value.senderRadius,
      receiverRadius: +this.updateBookingForm.value.receiverRadius
    }, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.response = result;
      //this.router.navigate(['/bookings/' + this.response.id]);
      //this.sentConfirmation = true;
    });
  }

  back() {
    this.router.navigate(['/']);
  }

}
