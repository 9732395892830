<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Super Agents</h2>
<table *ngIf="superAgents">
    <tr>
        <th>Id</th>
        <th>Name</th>
    </tr>
    <tr *ngFor="let superAgent of superAgents">
        <td><a [routerLink]="'/superagents/' + superAgent.id">{{ superAgent.id }}</a></td>
        <td>{{ superAgent.name }}</td>
    </tr>
</table>
<div *ngIf="!superAgents">
    Loading Super Agents
</div>