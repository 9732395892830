import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  payments?: any;

  user?: any;

  constructor(private httpClient: HttpClient, private router: Router, private wsService: WsService, private route: ActivatedRoute, private userService: UserService) { 
    this.payments = this.route.snapshot.data['payments'];
    /*this.bookingsService.bookingsSubject.subscribe((bookings) => {
      this.bookings = bookings;
    });*/
    this.userService.currentUserSubject.subscribe((user: any) => {
      this.user = user;
    });

    this.wsService.observable.subscribe((message: any) => {
      //let message = JSON.parse(messageEvent.data);
      console.log("Received message " + JSON.stringify(message));
      if (this.user) {
        if (message.type == 'payment') {
          if (message.sender === this.user.id || message.receiver == this.user.id) {
            this.updatePayment(message);
          }
        }
      }
    });

  }

  ngOnInit(): void {
    /*this.httpClient.get('/api/bookings', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result) => {
      this.bookings = result;
    })*/

    
  }

  updatePayment(message: any) {
    console.log('Updating payment');
    //let bookings = this.bookingsSubject.getValue();

    let found = false;
    for (let existingPayment of this.payments) {
      if (existingPayment.id == message.id) {
        existingPayment.sender = message.sender;
        existingPayment.receiver = message.receiver;
        existingPayment.type = message.type;
        existingPayment.date = message.date;
        found = true;
      }
    }

    if (!found) {
      let payment = { id: message.id, type: message.type, sender: message.sender, receiver: message.receiver, amount: message.amount, date: message.date };
      this.payments.unshift(payment);
    }

    //this.bookingsSubject.next(bookings);
  }

  pay(id: string) {
    
    let found = false;
    let payment;
    for (let aPayment of this.payments) {
      if (aPayment.id == id) {
        payment = aPayment;
        found = true;
      }
    }

    if (found) {
      this.router.navigate(['/create-booking'],{state:{receiver: payment.receiver, sender: payment.sender, amount: payment.amount, payment: payment.id }});
      /*this.httpClient.post('/api/payments/' + id, {
        amount: 0
      }, { headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)}).subscribe((result: any)=> {
        if (result.result == 0) {
          window.alert('Accepted');
        } else {
          window.alert('Denied');
        }
      })*/
    } else {
      console.log('Not found');
    }
  }

  openPayment(payment: any) {
    this.router.navigate(['/payments/' + payment.id ],{ state:{ payments: true }});
  }

  back() {
    this.router.navigate(['/']);
  }

}
