import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { LocationService } from 'src/app/services/location.service';
import { UserService } from 'src/app/services/user.service';
import { Location } from 'src/app/models/location';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit {

  isLoggedIn = false;

  location?: Location;

  constructor(private httpClient: HttpClient, private locationService: LocationService, private userService: UserService, private authService: AuthService) {
  
    this.isLoggedIn = this.authService.isAuthenticatedSubject.getValue();
    this.locationService.currentLocationSubject.asObservable().subscribe((location: Location) => {
      this.location = location;
      this.form.patchValue({latitude: '' + location.latitude});
      this.form.patchValue({longitude: '' + location.longitude});
    });
  }

  ngOnInit(): void {
  }

  sent = false;

  form: UntypedFormGroup = new UntypedFormGroup({
    type: new UntypedFormControl('ROAMING'),
    name: new UntypedFormControl(''),
    //email: new FormControl(''),
    //phone: new FormControl(''),
    latitude: new UntypedFormControl('' + this.location?.latitude),
    longitude: new UntypedFormControl('' + this.location?.longitude)
  });

  superagentform:UntypedFormGroup = new UntypedFormGroup({
    superagent: new UntypedFormControl(''),
  });

  setAgent() {
    let agent = {
      type: this.form.value.type,
      name: this.form.value.name,
      //email: this.form.value.email,
      //phone: this.form.value.phone,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude
    };
    this.httpClient.post('/api/agents',agent, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.sent = true;
    });
  }

  becomeAnAgent() {
    this.httpClient.post('/api/setagent',{agent: true}, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      let user = this.userService.currentUserSubject.getValue();
      user.agent = result.agent;
      this.userService.currentUserSubject.next(user);
    })
  }

  pauseAgent() {
    this.httpClient.post('/api/setpauseagent',{pauseAgent: true}, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {

    });
  }

  unPauseAgent() {
    this.httpClient.post('/api/setpauseagent',{pauseAgent: false}, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {

    });
  }

  setSuperAgent() {
    let body = {
      superagent: this.superagentform.value.superagent
    };
    this.httpClient.post('/api/setsuperagent',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.sent = true;
    });
  }


}
