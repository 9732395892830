<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>User Cash-Ins</h2>
<div class="input-group">
    <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        [(ngModel)]="model"
        (ngModelChange)="loadUserCashIns()"
        ngbDatepicker
        #d="ngbDatepicker"
    />
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
</div>
<div *ngIf="userCashIns && userCashIns.length == 0">
    No external cash-ins yet for this day!
</div>
<table *ngIf="userCashIns && userCashIns.length > 0" class="w-100">
    <tr>
        <th>Date</th>
        <th>Type</th>
        <th>User</th>
        <th>Receiver</th>
        <th>Reference</th>
        <th>Amount</th>
        <th>Status</th>
    </tr>
    <tr *ngFor="let userCashIn of userCashIns" (click)="openUserCashIn(userCashIn.id)" style="cursor: pointer">
        <td>{{ userCashIn.date | date }}</td>
        <td>{{ userCashIn.type }}</td>
        <td>{{ userCashIn.userDetail?userCashIn.userDetail.fullName:userCashIn.userId }}</td>
        <td>{{ userCashIn.receiverDetail?userCashIn.receiverDetail.fullName:userCashIn.receiver }}</td>
        <td>{{ userCashIn.reference }}</td>
        <td>{{ userCashIn.amount | currency:userCashIn.currency }}</td>
        <td>{{ userCashIn.complete?'Complete':'' }}</td>
    </tr>
</table>
<div *ngIf="!userCashIns">
    Loading External Cash-Ins
</div>